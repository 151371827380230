export default {
  async selectMaterialSistema (Vue, idmaterialSistema) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.material_sistema)
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .leftOuterJoin(
        tables.fabricante,
        tables.material_sistema.idfabricante.eq(tables.fabricante.idfabricante)
      )
      .leftOuterJoin(
        tables.agente_extintor,
        tables.material_sistema.idagente_extintor.eq(tables.agente_extintor.idagente_extintor)
      )
      .leftOuterJoin(
        tables.agente_propelente,
        tables.material_sistema.idagente_propelente.eq(tables.agente_propelente.idagente_propelente)
      )
      .leftOuterJoin(
        tables.timportancia_material,
        tables.material_sistema.idtimportancia_material.eq(tables.timportancia_material.idtimportancia_material)
      )
      .where(tables.material_sistema.idmaterial_sistema.eq(idmaterialSistema))
      .exec())[0]
  },
  async selectParteTrabajoMatsist(Vue, idmaterialSistema, idparteTrabajo) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.parte_trabajo_matsist)
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.parte_trabajo_matsist.idorden_trabajo_matsist)
      )
      .where(
        Vue.$offline.db.op.and(
          tables.parte_trabajo_matsist.idparte_trabajo.eq(idparteTrabajo),
          tables.orden_trabajo_matsist.idmaterial_sistema.eq(idmaterialSistema)
        )
      ).exec())[0]
  }
}
